<!--suppress SpellCheckingInspection -->
<template>
  <div>
    <p class="text-body-2 my-3 font-weight-bold">Today's Journal Entry</p>
    <p class="text-body-2 my-3">
      Review your day. What are you grateful for? What did you do right today, and are proud of? What could you have done better, and how will you make it easier for yourself to ack rite tomorrow?
    </p>
    
    <div v-if="loading">
      <v-progress-circular
      :size="100"
      color="amber"
      indeterminate
      ></v-progress-circular>
    </div>
    <div v-else> <!-- not loading -->

      <div v-if="!iWantToJournal" class="d-flex justify-center pt-16 justify-sm-start pt-sm-8">
        <v-btn class="btn-show-journal" @click.prevent="showTheJournal">write in my journal</v-btn>
      </div>
      <div v-else>
        <editor
          api-key="5g5urfy4wful0409hcapzw1vzuw82djwy0sq1ntob7eyjc51"
          cloud-channel="6"
          id="todays-journal"
          :init= "{
            selector: '#todays-journal',
            height: 500,
          }"
          plugins="link lists wordcount"
          toolbar="bold italic underline strikethrough numlist bullist link alignleft aligncenter alignright wordcount"
          v-model="todaysJournal"
        />
        <div class="d-flex flex-row-reverse">
          <v-btn @click.prevent="saveJournal" color="green" class="my-5">Save</v-btn>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import { updateJournal } from '@/services/JournalServices';
// import { createJournal, updateJournal } from '../../services/JournalServices';
export default {
  props: [ 'journal', 'uId', 'token', 'today', 'todayActual' ],
  data() {
    return {
      loading: true,
      iWantToJournal: false,

      todaysJournal: null,
      dateCreated: null,
      dateUpdated: null,
      brandNew: true,
    };
  },
  components: {
    'editor': Editor,
  },
  watch: {
    todaysJournal: function() {
      this.$cookies.set('todaysJournal', this.todaysJournal);
    },
  },
  computed: {},
  methods: {
    showTheJournal() {
      this.iWantToJournal = true;
    },
    async saveJournal() {
      console.log('starting saveJournal()');

      const j = {
        journal: this.todaysJournal,
        dateUpdated: this.todayActual,
        dateCreated: this.brandNew ? this.todayActual : this.dateCreated,
      };
      const success = await updateJournal(this.uId, this.token, this.today, j);
      if (success) {
        console.log('saveJournal() --> success');
      }
    },
    // todo - ah yes, (in reference to the todo below in this file), it does look like i was trying to get autosave going, and intended on using cookies. that means the auto-save is local to the machine you were using to write the journal, but NOT the cloud.
    // updateJournalCookie() {
    //   // do stuff
    //   return true;
    // },
  },
  created() {
    if (this.journal) {
      console.log('journal created()');
      this.todaysJournal = this.journal.journal;
      this.dateCreated = this.journal.dateCreated;
      this.dateUpdated = this.journal.dateUpdated;

      this.brandNew = false;
      this.iWantToJournal = true;

      // todo: why am i getting and setting cookies here? is it for the autosave functionality? not sure.
      if (this.$cookies.get('todaysJournal')) {
        if (this.todaysJournal !== this.$cookies.get('todaysJournal')) {
          this.todaysJournal = this.$cookies.get('todaysJournal');
          console.log('cookie is different');
        } else {
          console.log('cookie is same');
        }
      }
      else {
        this.$cookies.set('todaysJournal', this.todaysJournal);
        console.log('no cookie');
      }

    }
    else {
      this.todaysJournal = '';
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.btn-show-journal {
  background-color: gold;
  color: black;
  border: 1px solid black;
}
</style>
