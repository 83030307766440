<template>
  <div>
    <v-app-bar class="my-app-bar" fixed dark color="black">
      <v-app-bar-title @click="homeOrDash"><router-link class="text-yellow font-weight-bold" :to="homeOrDash">STACKIN Wins</router-link></v-app-bar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-spacer></v-spacer>
    <v-navigation-drawer 
      v-model="drawer" 
      temporary
      location="right"
    >
      <v-list nav>
        <v-list-item v-if="!isAuthenticated">
          <router-link to="/login">Login</router-link>
        </v-list-item>
        <v-list-item v-if="!isAuthenticated">
          <router-link to="/signup">Signup</router-link>
        </v-list-item>
        <v-list-item v-if="isAuthenticated">
          <router-link to="/dash">Dashboard</router-link>  
        </v-list-item>
        <v-list-item v-if="isAuthenticated">
          <router-link to="/my-account">My Account</router-link>  
        </v-list-item>
        <!-- <v-list-item>
          <router-link to="/performance-review">Review</router-link>
        </v-list-item> -->
        <v-list-item>
          <router-link to="/faq">FAQs</router-link>
        </v-list-item>
        <v-list-item v-if="isAuthenticated">
          <router-link to="#" @click.prevent="signOut">Sign out</router-link>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>

</template>

<script>
import { isUserLoggedIn, signTheUserOut } from "@/services/UserServices";

export default {
  computed: {
    isAuthenticated() {
      return isUserLoggedIn();
    },
    homeOrDash() {
      return this.isAuthenticated ? '/dash' : '/';
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    async signOut() {
      await signTheUserOut();
      this.$router.push('/');
    }
  }
}
</script>

<!-- <style lang="scss" scoped> -->
<style scoped>
a {
  color: black;
}
.v-list-item {
  text-align: right;
  min-height: 72px;
}
</style>
