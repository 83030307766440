<template>
  <v-app>
    <TheHeader />
    <v-main>
      <div class="page" justify="center">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import TheHeader from './components/TheHeader.vue';

export default {
  components: {
    TheHeader,
  },
  computed: {},
  created() {},
  watch: {}
}
</script>

<style scoped>
.page {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 82px;
}
</style>
