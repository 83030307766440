<template>
  <div class="px-4">
    <p class="text-h5 my-3 font-weight-bold">Edit WhatDo</p>

    <div v-if="!!title && !!options && options.length">
      <v-form @submit.prevent="updateWhatDo" density="compact" class="mt-8 mb-14">
        
        <p class="text-subtitle-2">Date created: {{ dateCreated }}</p>
        <p class="text-subtitle-2 mb-4">Last updated: {{ dateUpdated }}</p>
        
        <p class="font-weight-bold text-decoration-underline">title</p>
        <v-text-field v-model="title" class="font-weight-bold" placeholder="Title: what thing should you be doing?"></v-text-field>
      
        <p class="font-weight-bold text-decoration-underline mt-5">options</p>
        <ul class="ml-5">
          <li v-for="(_, i) in options" :key="i" class="d-flex justify-space-between">
            <v-text-field v-model="options[i]" clearable :placeholder="`option ${i+1}`"></v-text-field>
            <v-icon class="mr-2 text-red" @click.prevent="deleteOption(i)">
              mdi-trash-can-outline
            </v-icon>
      </li>
        </ul>
        <div class="d-flex justify-space-around mb-5 pb-5">
          <v-chip @click.prevent="addOption" variant="outlined">Add another option</v-chip>
        </div>


        <v-btn type="submit" :disabled="invalidWhatDo" block class="bg-green text-white">Update WhatDo</v-btn>

      </v-form>

      <div class="d-flex justify-space-between">
        <v-btn @click.prevent="cancel" class="bg-grey">Cancel</v-btn>
        <v-btn @click.prevent="deleteWhatDo" class="bg-red"><span class="text-white">Delete WhatDo</span></v-btn>
      </div>

    </div>
  </div>
</template>


<script>
import { getDates } from '@/services/DateServices';
import { getOneWhatDoFromDbase, updateOneWhatDo, deleteOneWhatDo } from '@/services/WhatDosServices';
import {isUserLoggedIn} from "@/services/UserServices";

export default {
  props: [ 'id' ],
  data() {
    return {
      uId: null,
      token: null,
      todayActual: null,

      title: null,
      options: null,
      dateCreated: null,
      dateUpdated: null,

    };
  },
  computed: {
    cleanedOptions() {
      let co = [];
      for (let i in this.options) {
        let option = this.options[i];
        if (option.trim().length > 0) {
          co.push(option);
        }
      }
      return co;
    },
    invalidWhatDo() {
      if (this.title.trim().length < 1 || this.cleanedOptions.length < 2) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async getWhatDo() {
      const wd = await getOneWhatDoFromDbase(this.uId, this.token, this.id);
      if (wd) {
        this.title = wd.title;
        this.options = wd.options;
        this.dateCreated = wd.dateCreated;
        this.dateUpdated = wd.dateUpdated;
      }
    },
    addOption() {
      this.options.push('');
    },
    async updateWhatDo() {
      console.log('starting updateWhatDo()');
      const wd = {
        id: this.id,
        title: this.title,
        options: this.cleanedOptions,
      }
      const success = await updateOneWhatDo(this.uId, this.token, this.todayActual, wd);
      if (success) {
        this.$router.push('/my-account');
      }
    },
    deleteOption(i) {
      console.log('starting deleteOption()', i);
      this.options.splice(i, 1);
    },
    cancel() {
      this.$router.push('/my-account');
    },
    async deleteWhatDo() {
      const success = await deleteOneWhatDo(this.uId, this.token, this.id);
      if (success) {
        this.$router.push('/my-account');
      }
    },
  },
  created() {
    // "auth"
    if (!isUserLoggedIn()) {
      this.$router.push('/');
    }

    // set vars
    this.$cookies.set('lastViewedTab', 'whatDos');
    this.uId = localStorage.getItem('uId');
    this.token = localStorage.getItem('idToken');

    // get dates
    const ta = getDates(null);
    this.todayActual = ta.todayActual;

    // get whatDos
    this.getWhatDo();
  },
};
</script>
