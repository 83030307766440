<template>
  <div>

    <p class="text-h5 mt-5 pt-5">Login</p>
    
    <v-form class="my-4">
      <v-text-field 
        label="Email" 
        variant="outlined" 
        type="email"
        v-model="email"
      ></v-text-field>

      <v-text-field 
        label="Password" 
        variant="outlined" 
        type="password" 
        v-model="pass"
      ></v-text-field>

      <v-btn color="green" @click.prevent="submitLoginForm">
        <span class="text-white">Login</span>
      </v-btn>

      <router-link to="/signup" class="ml-8">Sign up instead</router-link>
    </v-form>

  </div>
</template>



<script>
import { submitLogin, refreshToken } from '@/services/UserServices';
import { getDates } from '@/services/DateServices';

export default {
  data() {
    return {
      
      email: '',
      pass: '',
      loginDataIsValid: true,
      
    };
  },
  computed: {},
  methods: {

    async rT() {
      await refreshToken();
    },
    async submitLoginForm() {
      console.log('*** submitLoginForm() ***');
      const email = this.email.trim();
      const pass = this.pass.trim();

      // "validation"
      if (email.length > 0 && pass.length >= 6) {
        try {
          // get dates
          const ta = getDates(null);
          const d = ta.todayActual;
          console.log('*** todayActual ***', d);
          // log in
          const user = await submitLogin(email, pass);

          if (user) {
            console.log('*** response from submitLogin() > user ***', user);
            // auto-redirect to dashboard
            this.$router.push('/dash');
          }

        } catch(err) {
          console.log('*** Error in submitLoginForm() ***', err);
        }
      }
    },
  },
}
</script>

<style scoped>
</style>
